import React from 'react';

const Footer = () => {
    return (
        <footer>
         <div className="copyrights">
            <p>&copy;2024 All rights reserved | Designed & Developed by Put A Link On It</p>   
         </div>
      </footer>
    );
};

export default Footer;