import React from 'react';

const Contact = () => {
    return (
        <section className="contact-wrapper">
            <div className="heart-center">
                <img src="img/centershape.svg" alt="heart"></img>
            </div>
            <div className="section-title">
                <h2>επικοινωνία</h2>
            </div>
            <div className='phone-numbers flex'>
                <div className='vag'>
                    <span>Βαγγέλης</span>
                    <a href="tel:+306986829985"><strong>6986829985</strong></a>
                </div>
                <div className='dim'>
                    <span>Δήμητρα</span>
                    <a href="tel:+306988048367"><strong>6988048367</strong></a>
                </div>
            </div>
        </section>
    );
};

export default Contact;