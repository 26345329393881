import React from 'react';
import FirstSection from './FirstSection';
import LocationSection from './LocationSection';
import CountDown from './CountDown';
import BabySlider from './Slider';
import Contact from './Contact';

const Main = () => {
    return (
      <main>
        <FirstSection />
        <LocationSection />
        <CountDown />
        <BabySlider />
        <Contact />
      </main>
    );
  };
  
  export default Main;