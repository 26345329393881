import React, { useEffect } from 'react';

const LocationSection = () => {
    useEffect(() => {
        const heartCenter = document.querySelectorAll('.heart-center');
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const observerCallback = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        heartCenter.forEach(center => {
            observer.observe(center);
        });

        // Cleanup observer on component unmount
        return () => {
            heartCenter.forEach(center => {
                observer.unobserve(center);
            });
        };
    }, []);

    return (
        <section className="location-wrapper">
            <div className="section-title">
                <h2>τοποθεσία</h2>
            </div>
            <div className="location">
                <div className="church flex centered">
                    <div className="address">
                        <h3>Ιερός Ναός Αγίας Τριάδος</h3>
                        <h4>Γεναίου Κολοκοτρώνη 29, <br></br> Ναύπλιο 211 00</h4>
                    </div>
                    <div className="map">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.6169765146947!2d22.80563267612984!3d37.56408697203914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x149ffa9fac82a859%3A0x1b4599cb9fa0ad58!2zzpnOtc-Bz4zPgiDOnc6xz4zPgiDOkc6zzq_Osc-CIM6kz4HOuc6szrTOv8-C!5e0!3m2!1sel!2sgr!4v1724233715337!5m2!1sel!2sgr" 
                            width="600" 
                            style={{ border: 0 }}
                            allowFullScreen="" 
                            loading="lazy" 
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="heart-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="150.842" height="169.313" viewBox="0 0 120.842 125.313">
                    <defs>
                        <clipPath id="clip-path">
                            <rect id="Rectangle_44" data-name="Rectangle 44" width="97.331" height="103.654" fill="#D4AF37"/>
                        </clipPath>
                    </defs>
                    <g id="Group_86" data-name="Group 86" transform="matrix(0.966, -0.259, 0.259, 0.966, 0, 25.191)">
                        <g id="Group_85" data-name="Group 85" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <path className="divpath" id="Path_325" data-name="Path 325" d="M 1.487 74.6 C 3.257 59.603 7.758 45.056 14.767 31.68 C 20.9 20.036 29.562 8.262 42.381 3.577 C 54.461 -0.837 68.962 1.792 75.581 13.677 C 81.517 24.328 79.701 37.697 72.286 47.108 C 65.145 56.166 52.66 63.06 40.981 63.117 C 35.87 63.141 29.558 61.648 27.466 56.373 C 24.554 49.025 31.576 42.662 37.752 40.348 C 40.951 39.26 44.239 38.455 47.578 37.941 C 51.83 37.151 56.093 36.37 60.399 35.935 C 67.613 35.205 75.19 35.394 81.899 38.426 C 87.646 41.026 92.899 45.581 94.947 51.701 C 97.047 57.973 95.233 64.482 91.638 69.814 C 87.444 76.039 81.3 80.7 74.955 84.551 C 67.951 88.794 60.535 92.315 52.82 95.06 C 37.833 100.385 21.956 102.759 6.067 102.051" style={{ stroke: '#D4AF37', fill: 'none' }}/>
                        </g>
                    </g>
                </svg>
            </div>
        </section>
    );
};

export default LocationSection;
