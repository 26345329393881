import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";


const BabySlider = () => {
    return (
<Swiper
    className="childSwiper"
    slidesPerView={1.2}
    spaceBetween={10}
    loop={true}
    breakpoints={{
        500:{
            slidesPerView: 2.5,
        },
        900:{
            slidesPerView: 3.3,
            allowTouchMove: true,
        },
        1200: {
            slidesPerView: 4.5,
            allowTouchMove: true,
        },
    }}
>
    <SwiperSlide className="baby">
        <img src="img/xara1.jpg" alt="baby"></img>
    </SwiperSlide>
    <SwiperSlide className="baby">
        <img src="img/xara2.jpg" alt="baby"></img>
    </SwiperSlide>
    <SwiperSlide className="baby">
        <img src="img/xara3.jpg" alt="baby"></img>
    </SwiperSlide>
    <SwiperSlide className="baby">
        <img src="img/xara4.jpg" alt="baby"></img>
    </SwiperSlide>
    <SwiperSlide className="baby">
        <img src="img/isa1.jpg" alt="baby"></img>
    </SwiperSlide>
    <SwiperSlide className="baby">
        <img src="img/isa2.jpg" alt="baby"></img>
    </SwiperSlide>
    <SwiperSlide className="baby">
        <img src="img/isa3.jpg" alt="baby"></img>
    </SwiperSlide>
    <SwiperSlide className="baby">
        <img src="img/isa4.jpg" alt="baby"></img>
    </SwiperSlide>
</Swiper>
    );
};

export default BabySlider;