import React, { useState, useEffect } from 'react';
import ICalendarLink from "react-icalendar-link";

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const event = {
    title: 'Βάπτιση Ισαβέλλας',
    description: '',
    startTime: targetDate.toISOString(),
    location: 'Ιερός Ναός Αγίας Τριάδος , Γεναίου Κολοκοτρώνη 29, Ναύπλιο 211 00',
    filename: 'isavella.ics', 
  };


  return (
    <div>
    {Object.keys(timeLeft).length === 0 ? (
        <p>0 0 0 0</p>
    ) : (
        <div className="count flex">
            <div><p>{timeLeft.days}</p> <span>ημέρες</span></div>
            <div><p>{timeLeft.hours}</p><span>ώρες</span></div>
            <div><p>{timeLeft.minutes}</p><span>λεπτά</span></div>
            <div><p>{timeLeft.seconds}</p><span>δευτερόλεπτα</span></div>
        </div>
    )}
     <ICalendarLink event={event}>
        Προσθήκη στο ημερολόγιο
      </ICalendarLink>
    </div>
  );
};

export default CountdownTimer;